import React, { useEffect, useRef, useState } from "react"
import styles, { CARD_GAP } from "./Carousel.styles"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { useTheme } from "@basset-la/themed-components"

export type CardMovement = "no" | "prev" | "next"
const animationTimeout = 500

export interface Props {
  width: string
  children: React.ReactNode
  autoMoveTo?: number
  onMoved?: (first: number) => void
}

const Carousel: React.FC<Props> = ({ width, children, autoMoveTo, onMoved }) => {
  const theme = useTheme()

  const [first, setFirst] = useState(0)
  const [movement, setMovement] = useState<CardMovement>("no")
  const [maxVisibleItems, setMaxVisibleItems] = useState(1)
  const [cardWidth, setCardWidth] = useState(0)
  const [hideButtons] = useState(autoMoveTo !== undefined)

  const contentRef = useRef(null)
  const cardRef = useRef(null)

  const items = React.Children.toArray(children)

  useEffect(() => {
    if (
      contentRef &&
      cardRef &&
      contentRef!.current &&
      cardRef!.current &&
      contentRef!.current!["clientWidth"] &&
      cardRef!.current!["clientWidth"]
    ) {
      const max = Math.floor(contentRef!.current!["clientWidth"] / (cardRef!.current!["clientWidth"] + CARD_GAP))
      setMaxVisibleItems(max)
      setCardWidth(cardRef!.current!["clientWidth"])
    } else {
      setCardWidth(0)
    }
  }, [contentRef, cardRef])

  useEffect(() => {
    // TIP: Movement must be reset to "no" after any change.
    if (movement !== "no") {
      setTimeout(() => {
        setMovement("no")
      }, animationTimeout - 50)
    }
  }, [movement])

  useEffect(() => {
    if (autoMoveTo === undefined || autoMoveTo === first) return
    setFirst(autoMoveTo)
    setMovement(autoMoveTo < first ? "prev" : "next")
  }, [autoMoveTo, first])

  const getItems = () => {
    const selectedItems = []
    if (movement === "next") {
      selectedItems.push(items[first - 1])
    }
    for (var i = first; i < Math.min(items.length, first + maxVisibleItems + 1); i++) {
      selectedItems.push(items[i])
    }
    if (movement === "prev" && first + maxVisibleItems + 1 < items.length) {
      selectedItems.push(items[first + maxVisibleItems + 1])
    }
    return selectedItems
  }

  const handlePrevButtonClick = () => {
    const n = first - 1
    setFirst(n)
    setMovement("prev")
    if (onMoved) onMoved(n)
  }

  const handleNextButtonClick = () => {
    const n = first + 1
    setFirst(n)
    setMovement("next")
    if (onMoved) onMoved(n)
  }

  const showNext = maxVisibleItems === 0 ? first < items.length - 1 : first < items.length - maxVisibleItems

  return (
    <div className={styles.root(width)}>
      {!hideButtons && first > 0 && (
        <div className={styles.prevButton} onClick={handlePrevButtonClick}>
          <ArrowBackIosIcon className={styles.icon(theme)} />
        </div>
      )}
      <div ref={contentRef} className={styles.content}>
        {getItems().map((it, i) => (
          <div key={i} ref={cardRef} className={styles.card(movement, cardWidth, animationTimeout)}>
            {it}
          </div>
        ))}
      </div>
      {!hideButtons && showNext && (
        <div className={styles.nextButton} onClick={handleNextButtonClick}>
          <ArrowForwardIosIcon className={styles.icon(theme)} />
        </div>
      )}
    </div>
  )
}

export default Carousel
