import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import MaskedInput from "react-text-mask"
import { I18N_NS } from "../../utils/constants"

interface Props {
  inputRef: (ref: HTMLInputElement | null) => void
}

const CustomDatePickerInput: React.FC<Props> = (props: Props) => {
  const { inputRef, ...other } = props
  const { t } = useTranslation(I18N_NS)

  const datePickerMask = useMemo(() => {
    const datePickerMaskFromi18n = t("CustomerEditor.datePickerMask")

    const split = datePickerMaskFromi18n.split("/")

    let mask: Array<string | RegExp> = []
    for (let s of split) {
      let length = s.length

      while (length) {
        mask.push(/\d/)
        length -= 1
      }

      mask.push("/")
    }

    mask.pop()

    return mask
  }, [t])

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={datePickerMask}
      placeholderChar={"\u2000"}
      guide={false}
    />
  )
}

export default CustomDatePickerInput
