import React from "react"
import styles from "./Select.styles"

import Checkbox from "../Checkbox"
import MaterialTextField from "@material-ui/core/TextField"
import MaterialMenuItem from "@material-ui/core/MenuItem"
import { useTheme } from "@basset-la/themed-components"

type Value = {
  code: string
  name: string
}

export interface Props {
  values: Value[]
  onSelect: (v: string[]) => void
  selected?: string[]
  multiple?: boolean
  variant?: "checkbox" | "item"
  fullWidth?: boolean
  label?: string
  disabled?: boolean
  className?: string
  error?: boolean
  required?: boolean
  helperText?: string
  link?: React.ReactNode
  id?: string
  menuItemId?: string
}

const Select: React.FC<Props> = ({
  values,
  selected = [],
  onSelect,
  multiple = false,
  fullWidth = false,
  label = "",
  disabled = false,
  variant = "checkbox",
  className,
  error,
  helperText,
  required,
  link,
  id,
  menuItemId
}) => {
  const theme = useTheme()

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    if (multiple) onSelect(event.target.value as string[])
    else onSelect([event.target.value as string])
  }

  const renderValue = (value: unknown): React.ReactNode => {
    let label = ""
    const list = value as string[]

    if (multiple) {
      for (const i in list) {
        const s = values.find(e => e.code === list[i])
        if (s) label += `${s.name}`
        if (parseInt(i) < list.length - 1) label += `, `
      }

      return label
    } else {
      const selected = values.find(e => e.code === (value as string[]).join(""))
      if (selected) return selected.name
      else return ""
    }
  }

  return (
    <MaterialTextField
      id={id}
      variant="outlined"
      select
      label={label}
      fullWidth={fullWidth}
      error={error}
      helperText={helperText}
      required={required}
      value={selected}
      className={`${styles.select(theme, fullWidth)} ${className}`}
      disabled={disabled}
      SelectProps={{ multiple: multiple, onChange: handleChange, renderValue: renderValue }}
    >
      {values.map((v, i) => (
        <MaterialMenuItem key={i} value={v.code} id={menuItemId}>
          {variant === "checkbox" && (
            <Checkbox checked={selected.findIndex(e => e === v.code) > -1} className={styles.checkbox} />
          )}
          {v.name}
        </MaterialMenuItem>
      ))}
      {link && <MaterialMenuItem>{link}</MaterialMenuItem>}
    </MaterialTextField>
  )
}

export default Select
