import React from "react"
import styles from "./Button.styles"

import MaterialButton from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useTheme } from "@basset-la/themed-components"

type Variant = "text" | "contained" | "outlined"
type Shape = "rounded" | "rectangular"

export interface Props {
  children: React.ReactNode
  onClick: React.MouseEventHandler<HTMLButtonElement>
  variant?: Variant
  shape?: Shape
  disabled?: boolean
  loading?: boolean
  success?: boolean
  fullWidth?: boolean
  className?: string
  id?: string
}

const Button: React.FC<Props> = ({
  children,
  onClick,
  variant = "contained",
  shape = "rectangular",
  disabled,
  loading,
  success,
  fullWidth,
  className,
  id
}) => {
  const theme = useTheme()

  return (
    <div className={`${styles.container} ${fullWidth && styles.fullWidth} ${className}`}>
      <MaterialButton
        id={id}
        fullWidth
        onClick={onClick}
        disabled={disabled}
        variant={variant}
        className={`${styles[variant](theme)} ${styles[shape]} ${success ? `${styles.success(theme)}` : null} ${
          styles.button
        }`}
      >
        {children}
      </MaterialButton>
      {loading && <CircularProgress size={24} className={`${styles.loading(theme)}`} />}
    </div>
  )
}

export default Button
