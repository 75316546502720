import React from "react"
import styles from "./AppLoader.styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import FlightsIcon from "../../icons/FlightsIcon"
import AccommodationsIcon from "../../icons/AccommodationsIcon"
import CarsIcon from "../../icons/CarsIcon"

export interface Props {
  variant: "FLIGHTS" | "ACCOMMODATIONS" | "CARS"
}

const AppLoader: React.FC<Props> = ({ variant }) => {
  return (
    <div className={styles.containerStyle}>
      <div className={styles.mainContainerStyle}>
        <div className={styles.imgContainerStyle}>
          {variant === "FLIGHTS" && <FlightsIcon />}
          {variant === "ACCOMMODATIONS" && <AccommodationsIcon />}
          {variant === "CARS" && <CarsIcon />}
        </div>
        <LinearProgress className={styles.linearStyle} />
      </div>
    </div>
  )
}

export default AppLoader
