import { useTheme } from "@basset-la/themed-components"
import IconButton from "@material-ui/core/IconButton"
import AddIcon from "@material-ui/icons/AddCircleOutlineRounded"
import CancelIcon from "@material-ui/icons/HighlightOffRounded"
import cloneDeep from "lodash.clonedeep"
import moment from "moment"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Button from "../Button"
import Link from "../Link"
import Select from "../Select"
import Text from "../Text"
import TextField from "../TextField"
import CustomDatePickerInput from "./CustomDatePickerInput"
import styles from "./CustomerEditor.styles"
import { Country, Customer, CustomerDocumentType, Phone, PhoneType } from "./types"

export interface Props {
  title: string
  countries: Country[]
  onSave: (c: Customer) => void

  loading?: boolean
  customer?: Customer
  subtitle?: string
  site?: string
  config?: Config
  validateSpecialsFields?: boolean
  maxLength?: number
}

type Config = {
  firstName: FieldConfig
  lastName: FieldConfig
  email: FieldConfig
  document: FieldConfig
  birthDate: FieldConfig
  nationality: FieldConfig
  gender: FieldConfig
  phone: FieldConfig
}

type FieldConfig = {
  enabled?: boolean
  mandatory?: boolean
  advanced?: boolean
}

type DocumentError = {
  number?: string
  issuing_country?: string
  valid_thru?: string
}

type Errors = {
  first_name?: string
  last_name?: string
  email?: string
  document: DocumentError[]
  birth_date?: string
  nationality?: string
}

const DEFAULT_CONFIG: Config = {
  firstName: {
    enabled: true,
    mandatory: true
  },
  lastName: {
    enabled: true,
    mandatory: true
  },
  birthDate: {
    enabled: true,
    mandatory: true
  },
  document: {
    enabled: true,
    mandatory: true,
    advanced: true
  },
  email: {
    enabled: true,
    mandatory: true
  },
  gender: {
    enabled: true
  },
  nationality: {
    enabled: true
  },
  phone: {
    enabled: true
  }
}

const CustomerEditor: React.FC<Props> = ({
  customer,
  countries,
  title,
  subtitle,
  onSave,
  site,
  config = DEFAULT_CONFIG,
  loading,
  validateSpecialsFields = false,
  maxLength = 25
}) => {
  const defaultCustomer: Customer = customer
    ? customer
    : {
        id: "",
        birth_date: "",
        client_id: "",
        documents: [{ type: "CI", issuing_country: site ? site : "", number: "", valid_thru: "" }],
        email: "",
        first_name: "",
        gender: "M",
        last_name: "",
        nationality: site ? site : "",
        phone: {
          area_code: "",
          country_code: "",
          number: "",
          type: "H"
        }
      }

  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)
  const [currentCustomer, setCurrentCustomer] = useState<Customer>(defaultCustomer)
  const [birthDate, setBirthDate] = useState<string>(defaultCustomer.birth_date)
  const [errors, setErrors] = useState<Errors>({ document: [] })

  const handleOnchangeTextField = (key: keyof Pick<Customer, "first_name" | "last_name" | "email">) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const c = cloneDeep(currentCustomer)
    const err = cloneDeep(errors)

    let regex
    if (key === "first_name" || key === "last_name") {
      regex = /^[a-zA-Z\sñáéíóúÁÉÍÓÚ]*$/
      if (regex.test(e.target.value)) {
        if (validateSpecialsFields && e.target.value.length > maxLength) return
        c[key] = e.target.value
      }
    } else if (key === "email") {
      regex = /^[a-zA-Z0-9@._%+-]*$/
      if (regex.test(e.target.value)) {
        c[key] = e.target.value
      }
    }
    if (!regex) c[key] = e.target.value
    err[key] = undefined

    setErrors(err)
    setCurrentCustomer(c)
  }

  const handleOnchangeDocumentType = (i: number) => (v: string[]) => {
    const c = cloneDeep(currentCustomer)
    c.documents[i].type = v[0] as CustomerDocumentType

    setCurrentCustomer(c)
  }

  const handleOnchangeDocumentCountry = (i: number) => (v: string[]) => {
    const c = cloneDeep(currentCustomer)
    c.documents[i].issuing_country = v[0]

    const errs = cloneDeep(errors)
    if (errs.document[i]) {
      errs.document[i].issuing_country = undefined
      setErrors(errs)
    }

    setCurrentCustomer(c)
  }

  const handleOnDocumentNumberChange = (i: number) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const c = cloneDeep(currentCustomer)

    if (c.documents[i].type === "PAS") c.documents[i].number = e.target.value
    else if (/^[0-9]*$/.test(e.target.value)) c.documents[i].number = e.target.value

    const errs = cloneDeep(errors)
    if (errs.document[i]) {
      errs.document[i].number = undefined
      setErrors(errs)
    }

    setCurrentCustomer(c)
  }

  const handleOnChangeBirthDate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const err = cloneDeep(errors)

    err.birth_date = undefined

    setErrors(err)
    setBirthDate(e.target.value)
  }

  const handleOnChangeDocumentValidThruDate = (i: number) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const c = cloneDeep(currentCustomer)
    c.documents[i].valid_thru = e.target.value

    const errs = cloneDeep(errors)
    if (errs.document[i]) {
      errs.document[i].valid_thru = undefined
      setErrors(errs)
    }

    setCurrentCustomer(c)
  }

  const handleOnAddDocument = () => {
    const c = cloneDeep(currentCustomer)
    c.documents.push({
      issuing_country: site ? site : "",
      number: "",
      type: "CI",
      valid_thru: ""
    })

    setCurrentCustomer(c)
  }

  const handleOnChangeSelect = (key: keyof Pick<Customer, "nationality" | "gender">) => (v: string[]) => {
    const c = cloneDeep(currentCustomer)
    c[key] = v[0] as any

    if (key === "nationality") {
      const err = cloneDeep(errors)
      err.nationality = undefined
      setErrors(err)
    }

    setCurrentCustomer(c)
  }

  const handleOnSelectPhoneType = (v: string[]) => {
    const c = cloneDeep(currentCustomer)
    c.phone.type = v[0] as PhoneType

    setCurrentCustomer(c)
  }

  const handleOnPhoneChange = (key: keyof Pick<Phone, "area_code" | "country_code" | "number">) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const c = cloneDeep(currentCustomer)

    if (/^[0-9]*$/.test(e.currentTarget.value)) c.phone[key] = e.currentTarget.value

    setCurrentCustomer(c)
  }

  const handleOnSave = () => {
    if (validate()) {
      onSave({ ...currentCustomer, birth_date: birthDate })
    }
  }

  const validate = (): boolean => {
    let valid = true
    let e: Errors = {
      document: []
    }

    if (config.firstName.mandatory && currentCustomer.first_name === "") {
      e.first_name = t("CustomerEditor.mandatoryField")
      valid = false
    }

    if (config.lastName.mandatory && currentCustomer.last_name === "") {
      e.last_name = t("CustomerEditor.mandatoryField")
      valid = false
    }

    if (config.email.enabled) {
      if (config.email.mandatory && currentCustomer.email === "") {
        e.email = t("CustomerEditor.mandatoryField")
        valid = false
      } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(currentCustomer.email)) {
        e.email = t("CustomerEditor.invalidEmail")
        valid = false
      }
    }

    if (config.document.enabled) {
      let docErrs: DocumentError[] = []
      for (let idx = 0; idx < currentCustomer.documents.length; idx++) {
        const doc = currentCustomer.documents[idx]
        if (config.document.mandatory) {
          if (doc.number === "") {
            if (docErrs[idx] === undefined) {
              docErrs[idx] = {}
            }
            docErrs[idx].number = t("CustomerEditor.mandatoryField")
            valid = false
          }
          if (config.document.advanced) {
            if (doc.issuing_country === "") {
              if (docErrs[idx] === undefined) {
                docErrs[idx] = {}
              }
              docErrs[idx].issuing_country = t("CustomerEditor.mandatoryField")
              valid = false
            }
            if (doc.valid_thru !== "") {
              const d = moment(doc.valid_thru, "DD/MM/YYYY", true)
              if (!d.isValid()) {
                docErrs[idx].valid_thru = t("CustomerEditor.invalidDate")
                valid = false
              } else {
                if (validateSpecialsFields) {
                  // 315360000000 = 10 * 365 * 24 * 60 * 60 * 1000 --- ten years
                  const date10Years = new Date(Date.now() + 315360000000)
                  const dateThru = new Date(d.format("YYYY/MM/DD"))

                  valid = date10Years.getTime() > dateThru.getTime() && Date.now() < dateThru.getTime()
                }
                if (!valid) docErrs[idx].valid_thru = t("CustomerEditor.invalidDate")
              }
            } else {
              if (validateSpecialsFields) docErrs[idx].valid_thru = t("CustomerEditor.mandatoryField")
            }
          }
        }
      }
      e.document = docErrs
    }

    if (config.birthDate.enabled) {
      if (birthDate === "") {
        if (config.birthDate.mandatory) {
          e.birth_date = t("CustomerEditor.mandatoryField")
          valid = false
        }
      } else {
        const d = moment(birthDate, "DD/MM/YYYY", true)
        if (!d.isValid()) {
          e.birth_date = t("CustomerEditor.invalidDate")
          valid = false
        }
      }
    }

    if (config.nationality.mandatory && currentCustomer.nationality === "") {
      e.nationality = t("CustomerEditor.mandatoryField")
      valid = false
    }

    setErrors(e)

    return valid
  }

  const handleRemoveDocument = (i: number) => () => {
    const c = cloneDeep(currentCustomer)
    c.documents.splice(i, 1)

    const errs = cloneDeep(errors)
    if (errs.document[i]) {
      errs.document.splice(i, 1)
      setErrors(errs)
    }

    setCurrentCustomer(c)
  }

  return (
    <div className={styles.root(theme)}>
      <div className={styles.title}>
        <Text size={24} variant="light">
          {title}
        </Text>
        {subtitle !== undefined && (
          <Text size={14} color="variant">
            {subtitle}
          </Text>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.nameContainer}>
          {config.firstName.enabled && (
            <div className={styles.nameTextField}>
              <TextField
                onChange={handleOnchangeTextField("first_name")}
                label={t("CustomerEditor.fistName")}
                required={config.firstName.mandatory}
                value={currentCustomer.first_name}
                fullWidth
                error={errors.first_name !== undefined}
                helperText={errors.first_name}
                disabled={loading}
              />
            </div>
          )}
          {config.lastName.enabled && (
            <div className={styles.nameTextField}>
              <TextField
                className={styles.nameTextField}
                onChange={handleOnchangeTextField("last_name")}
                label={t("CustomerEditor.lastName")}
                required={config.lastName.mandatory}
                value={currentCustomer.last_name}
                fullWidth
                error={errors.last_name !== undefined}
                helperText={errors.last_name}
                disabled={loading}
              />
            </div>
          )}
        </div>
        {config.email.enabled && (
          <div className={styles.email}>
            <TextField
              onChange={handleOnchangeTextField("email")}
              label={t("CustomerEditor.email")}
              required={config.email.mandatory}
              value={currentCustomer.email}
              fullWidth
              error={errors.email !== undefined}
              helperText={errors.email}
              disabled={loading}
            />
          </div>
        )}
        {config.document.enabled && (
          <>
            <Text size={14} color="variant" className={styles.textMargin}>
              {t("CustomerEditor.documentTitle")} {config.document.mandatory ? "*" : ""}
            </Text>
            {currentCustomer.documents.map((d, i) => (
              <div key={i} className={styles.documentContainer}>
                <div className={styles.documentForm}>
                  <div className={styles.documentFormElements}>
                    <div className={styles.documentType}>
                      <Select
                        variant="item"
                        label={t("CustomerEditor.type")}
                        disabled={loading}
                        values={[
                          {
                            code: "CI",
                            name: t("CustomerEditor.documentTypeLocal")
                          },
                          {
                            code: "PAS",
                            name: t("CustomerEditor.documentTypePassport")
                          }
                        ]}
                        selected={[currentCustomer.documents[i].type]}
                        onSelect={handleOnchangeDocumentType(i)}
                        fullWidth
                      />
                    </div>
                    <div className={styles.documentNumber}>
                      <TextField
                        onChange={handleOnDocumentNumberChange(i)}
                        label={t("CustomerEditor.documentNumber")}
                        fullWidth
                        disabled={loading}
                        error={errors.document[i] && errors.document[i].number !== undefined}
                        value={currentCustomer.documents[i].number}
                        helperText={errors.document[i] ? errors.document[i].number : ""}
                      />
                    </div>
                  </div>
                  {config.document.advanced && (
                    <div className={styles.documentFormElements}>
                      <div className={styles.documentCountry}>
                        <Select
                          variant="item"
                          values={countries.map(c => ({
                            code: c.code,
                            name: c.name
                          }))}
                          disabled={loading}
                          label={t("CustomerEditor.country")}
                          onSelect={handleOnchangeDocumentCountry(i)}
                          fullWidth
                          error={errors.document[i] && errors.document[i].issuing_country !== undefined}
                          helperText={errors.document[i] ? errors.document[i].issuing_country : ""}
                          selected={[currentCustomer.documents[i].issuing_country]}
                        />
                      </div>
                      <div className={styles.documentValidThru}>
                        <TextField
                          value={currentCustomer.documents[i].valid_thru}
                          disabled={loading}
                          placeholder={t("CustomerEditor.datePickerMask")}
                          label={t("CustomerEditor.validThru")}
                          fullWidth
                          onChange={handleOnChangeDocumentValidThruDate(i)}
                          inputComponent={CustomDatePickerInput as any}
                          error={errors.document[i] && errors.document[i].valid_thru !== undefined}
                          helperText={errors.document[i] ? errors.document[i].valid_thru : ""}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {currentCustomer.documents.length > 1 && (
                  <IconButton disabled={loading} className={styles.buttonIcon(theme)} onClick={handleRemoveDocument(i)}>
                    <CancelIcon />
                  </IconButton>
                )}
              </div>
            ))}
            <Link
              className={styles.addDocument}
              onClick={handleOnAddDocument}
              component="div"
              TextProps={{ size: 16, component: "p", className: styles.addDocumentContent }}
              value={
                <>
                  <AddIcon /> <span>{t("CustomerEditor.addDocument")}</span>
                </>
              }
            />
          </>
        )}
        {config.birthDate.enabled && (
          <div className={styles.birthDateContainer}>
            <TextField
              value={birthDate}
              disabled={loading}
              placeholder={t("CustomerEditor.datePickerMask")}
              required={config.birthDate.mandatory}
              label={t("CustomerEditor.birthDate")}
              fullWidth
              onChange={handleOnChangeBirthDate}
              inputComponent={CustomDatePickerInput as any}
              error={errors.birth_date !== undefined}
              helperText={errors.birth_date}
            />
          </div>
        )}
        {(config.gender.enabled || config.nationality.enabled) && (
          <div className={styles.countryGenderContainer}>
            {config.nationality.enabled && (
              <div className={styles.nationality}>
                <Select
                  variant="item"
                  label={t("CustomerEditor.nationality")}
                  values={countries.map(c => ({
                    code: c.code,
                    name: c.name
                  }))}
                  fullWidth
                  disabled={loading}
                  required={config.nationality.mandatory}
                  onSelect={handleOnChangeSelect("nationality")}
                  selected={[currentCustomer.nationality]}
                  error={errors.nationality !== undefined}
                  helperText={errors.nationality}
                />
              </div>
            )}
            {config.gender.enabled && (
              <div className={styles.gender}>
                <Select
                  variant="item"
                  label={t("CustomerEditor.gender")}
                  values={[
                    {
                      code: "M",
                      name: t("CustomerEditor.male")
                    },
                    {
                      code: "F",
                      name: t("CustomerEditor.female")
                    }
                  ]}
                  fullWidth
                  disabled={loading}
                  required={config.gender.mandatory}
                  onSelect={handleOnChangeSelect("gender")}
                  selected={[currentCustomer.gender]}
                />
              </div>
            )}
          </div>
        )}
        {config.phone.enabled && (
          <div className={styles.phoneNumberContainer}>
            <Text size={14} color="variant" className={styles.textMargin}>
              {t("CustomerEditor.phoneNumber")}
            </Text>
            <div className={styles.phone}>
              <div className={styles.phoneType}>
                <Select
                  variant="item"
                  values={[
                    {
                      code: "M",
                      name: t("CustomerEditor.mobile")
                    },
                    {
                      code: "H",
                      name: t("CustomerEditor.home")
                    }
                  ]}
                  fullWidth
                  disabled={loading}
                  onSelect={handleOnSelectPhoneType}
                  selected={[currentCustomer.phone.type]}
                />
              </div>
              <div className={styles.countryCode}>
                <TextField
                  onChange={handleOnPhoneChange("country_code")}
                  label={t("CustomerEditor.countryCode")}
                  value={currentCustomer.phone.country_code}
                  fullWidth
                  disabled={loading}
                />
              </div>
              <div className={styles.areaCode}>
                <TextField
                  onChange={handleOnPhoneChange("area_code")}
                  label={t("CustomerEditor.areaCode")}
                  value={currentCustomer.phone.area_code}
                  fullWidth
                  disabled={loading}
                />
              </div>
              <div className={styles.phoneNumber}>
                <TextField
                  onChange={handleOnPhoneChange("number")}
                  label={t("CustomerEditor.number")}
                  value={currentCustomer.phone.number}
                  fullWidth
                  disabled={loading}
                />
              </div>
            </div>
          </div>
        )}
        <div className={styles.buttonContainer}>
          <Button onClick={handleOnSave} variant="contained" loading={loading} disabled={loading}>
            {t("CustomerEditor.save")}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CustomerEditor
