import React, { useRef, useState } from "react"
import styles from "./Header.styles"
import { useTheme } from "@basset-la/themed-components"
import WarningRounded from "@material-ui/icons/WarningRounded"
import { useTranslation } from "react-i18next"
import Link from "@material-ui/core/Link"
import FlightIcon from "@material-ui/icons/Flight"
import HotelIcon from "@material-ui/icons/Hotel"
import PhoneIcon from "@material-ui/icons/Phone"
import WorkIcon from "@material-ui/icons/Work"
import CarIcon from "@material-ui/icons/DirectionsCar"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { HeaderButton } from "../../models/types"
import SocialLink from "../SocialLink/SocialLink"
import SocialMenu from "./SocialMenu"
import { HeaderFlow, SocialLinkItem } from "../../models/types"
import { I18N_NS } from "../../utils/constants"
import Text from "../Text"
import UserMenu from "./UserMenu"

export interface Props {
  /**
   * Param set the flow style header
   * @param flow
   * @value "availability" | "checkout"
   * @required true
   */
  flow: HeaderFlow
  /**
   * Company name used in img>alt prop
   * @param company
   * @value string
   * @required true
   */
  company: string
  /**
   * URL used in logo image
   * @param logo
   * @value string
   * @required true
   */
  logo: string
  /**
   * Array of buttons to use on navigation bar
   * @param buttons
   * @value "flights" | "accommodations" | "trip"
   * @required false
   */
  products?: HeaderButton[]
  /**
   * Boolean used to identify development environments
   * @param development
   * @value boolean
   * @required false
   */
  development?: boolean
  /**
   * Phone number
   * @param phone
   * @value string
   * @required false
   */
  phone?: string
  /**
   * Array of social networks urls
   * @param socialNetworks
   * @value SocialLinkItem[]
   * @required false
   */
  socialNetworks?: SocialLinkItem[]
  isAuthenticated?: boolean
  showBackOfficeOption?: boolean
  onAccessBackOffice?: () => void
  onLogout?: () => void
}

const Header: React.FunctionComponent<Props> = ({
  flow,
  company,
  logo,
  products,
  development,
  phone,
  socialNetworks,
  isAuthenticated,
  showBackOfficeOption,
  onAccessBackOffice,
  onLogout
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation(I18N_NS)
  const [showSocialMenu, setShowSocialMenu] = useState(false)
  const menuRef = useRef(null)

  const availableIcons = {
    flights: FlightIcon,
    accommodations: HotelIcon,
    trip: WorkIcon,
    cars: CarIcon
  }

  const handleOpenSocialMenu = () => {
    setShowSocialMenu(true)
  }

  const handleCloseSocialMenu = () => {
    setShowSocialMenu(false)
  }

  const handleSocialLinkClick = (url: string) => {
    setShowSocialMenu(false)
    window.open(url, "_blank")?.focus()
  }

  const handleAccessBackOffice = () => {
    if (onAccessBackOffice) {
      onAccessBackOffice()
    }
  }

  const handleLogout = () => {
    if (onLogout) {
      onLogout()
    }
  }

  return (
    <header className={styles.container}>
      <div className={`${styles.content(colors.background_primary)}`}>
        <div className={styles.innerContentTop}>
          <img className={styles.logo} src={logo} alt={company} />
          <div className={styles.contactContainer(colors.brand_primary)}>
            <div className={styles.contact}>
              {phone && (
                <Link className={styles.phoneLink(colors.brand_primary)} href={`tel:${phone}`} underline="none">
                  <PhoneIcon />
                  <Text className={styles.contactText(colors.brand_primary)} size={14} variant="bold">
                    {phone}
                  </Text>
                </Link>
              )}
              {socialNetworks && socialNetworks.length > 0 && (
                <div
                  className={styles.socialMenuIconButton(colors.brand_primary)}
                  ref={menuRef}
                  onClick={handleOpenSocialMenu}
                >
                  <MoreVertIcon />
                </div>
              )}
            </div>
            {socialNetworks && socialNetworks.length > 0 && (
              <div className={styles.social}>
                {socialNetworks.map((item, i) => (
                  <SocialLink key={i} color={colors.brand_primary} item={item} onClick={handleSocialLinkClick} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {flow === "availability" && (
        <nav className={styles.nav(colors.brand_primary)}>
          <div className={styles.innerContent}>
            {products &&
              products.map((p, i) => {
                const Icon = availableIcons[p.type]

                return (
                  <Link
                    key={i}
                    href={p.path}
                    underline="none"
                    className={styles.link(colors.text_white, colors.brand_variant, colors.brand_darker, !!p.selected)}
                  >
                    <Icon className={styles.linkIcon} />
                    <span>{t(`Header.${p.type}`)}</span>
                  </Link>
                )
              })}
          </div>
          {isAuthenticated && (
            <div className={styles.auth}>
              <UserMenu
                variant="row"
                showBackOfficeOption={showBackOfficeOption || false}
                onLogout={handleLogout}
                onAccessBackOffice={handleAccessBackOffice}
              />
            </div>
          )}
        </nav>
      )}
      {development && (
        <div className={styles.developmentAlert(colors.warning)}>
          <div className={styles.innerContent}>
            <WarningRounded className={styles.developmentAlertIcon} /> {t("Header.developmentAlert")}
          </div>
        </div>
      )}
      {menuRef && (
        <SocialMenu
          open={showSocialMenu}
          anchor={menuRef.current!}
          socialNetworks={socialNetworks || []}
          isAuthenticated={isAuthenticated || false}
          showBackOfficeOption={showBackOfficeOption || false}
          onClose={handleCloseSocialMenu}
          onSelect={handleSocialLinkClick}
          onLogout={handleLogout}
          onAccessBackOffice={handleAccessBackOffice}
        />
      )}
    </header>
  )
}

export default Header
